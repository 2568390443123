
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "/public/css/reset.css";
import { AuthProvider } from "@lib/auth/ui";
import { ChakraProvider, ProgressBar, RtlProvider, theme } from "@ui/index";
import { logEvent, logPageView } from "@util/analytics";
import { queryClient } from "@util/query";
import type { NextPage } from "next";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import Head from "next/head";
import Router from "next/router";
import { QueryClientProvider } from "react-query";
import "@fontsource/inter";
import "@fontsource/inter-tight";
import "@fontsource/inter-tight/100.css";
import "@fontsource/inter-tight/200.css";
import "@fontsource/inter-tight/300.css";
import "@fontsource/inter-tight/400.css";
import "@fontsource/inter-tight/500.css";
import "@fontsource/inter-tight/600.css";
import "@fontsource/inter-tight/700.css";
import "@fontsource/inter-tight/800.css";
import "@fontsource/inter-tight/900.css";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
// Supports weights 400-900
import "@fontsource-variable/golos-text";
// Arabic font
import "@fontsource/noto-sans-arabic";
import "@fontsource/noto-sans-arabic/100.css";
import "@fontsource/noto-sans-arabic/200.css";
import "@fontsource/noto-sans-arabic/300.css";
import "@fontsource/noto-sans-arabic/400.css";
import "@fontsource/noto-sans-arabic/500.css";
import "@fontsource/noto-sans-arabic/600.css";
import "@fontsource/noto-sans-arabic/700.css";
import "@fontsource/noto-sans-arabic/800.css";
import "@fontsource/noto-sans-arabic/900.css";
import "/public/font-icon-style.css";
import "/public/fonts/cervino.css";
import "/public/fonts/margit-heavy.css";
// slick-carousel
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "/public/css/slick.css";
// Custom
import "/public/css/animated-btn.css";
const progress = new ProgressBar();
type Page<P = Record<string, unknown>> = NextPage<P>;
type Props = AppProps & {
    Component: Page;
};
Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeError", progress.finish);
Router.events.on("routeChangeComplete", () => {
    logPageView();
    progress.finish();
});
export const reportWebVitals = ({ id, name, label, value, }: NextWebVitalsMetric) => {
    logEvent({
        name,
        category: label === "web-vital" ? "Web Vitals" : "Custom metric",
        label: id,
        value: Math.round(name === "CLS" ? value * 1000 : value),
        interaction: false,
    });
};
const MyApp = ({ Component, pageProps }: Props) => {
    return (<ChakraProvider theme={theme}>
      <RtlProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        </Head>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Component {...pageProps}/>
          </AuthProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </RtlProvider>
    </ChakraProvider>);
};
const __Next_Translate__Page__19503823eac__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__19503823eac__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  